import React from "react";
import { Link } from "gatsby";

import styles from "./service.module.css";

class Service extends React.Component {
  render() {
    return (
      <div className={`${styles.service} grid-cell`}>
        <div className="aligner-item aligner-item-bottom">
          <Link className={styles.service} to={this.props.serviceURL}>
            <h3 className={styles.serviceTitle}>{this.props.serviceName}</h3>
          </Link>
          <p>{this.props.serviceDescription}</p>
          <div className={styles.serviceButton}>
            <Link
              to={this.props.serviceURL}
              className="button button-secondary"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
