import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import Service from "../components/service";
import ServiceList from "../components/serviceList";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";
import DidYouKnow from "../components/didYouKnow";

class PersonalInsurance extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Baton Rouge Personal Insurance | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "As a Baton Rouge, Louisiana resident, choosing Lewis Mohr Insurance Agency, a family-owned, independent insurance agency, will give you piece of mind knowing you have a trusted partner. Contact us for a quote!",
            },
          ]}
        />
        <MainBg
          mainBg={this.props.data.mainBg}
          alt="Family and children at the table."
        />
        <Callout
          title="Baton Rouge Personal Insurance"
          content="As a Baton Rouge, Louisiana resident, choosing Lewis Mohr Insurance Agency, a family-owned, independent insurance agency, will give you piece of mind knowing you have a trusted partner. Insurance is not a commodity, but a specialized product, which is why it's important to have local experts like us in your corner. Below you'll find our most popular personal insurance offerings that you can learn more about."
        ></Callout>
        <div className="site-body page-content">
          <h2>For You & Your Family</h2>
          <div className="services-title-seperator"></div>
          <ServiceList>
            <Service
              serviceName="Homeowners Insurance"
              serviceURL={"/homeowners-insurance/"}
              serviceDescription="Covers losses and damages to a house and assets in the home. Includes interior damage, exterior damage, injuries, and more."
            />
            <Service
              serviceName="Flood Insurance"
              serviceURL={"/flood-insurance/"}
              serviceDescription="Covers damage to your home directly resulting from flooding. Not included in most homeowners insurance policies."
            />
            <Service
              serviceName="Auto Insurance"
              serviceURL={"/auto-insurance//"}
              serviceDescription="For your vehicles, covering property damage, bodily injury, uninsured motorists, and more."
            />
            <Service
              serviceName="Life Insurance"
              serviceURL={"/life-insurance/"}
              serviceDescription="Insurance that pays a designated beneficiary a sum of money upon the death of an insured person."
            />
            <DidYouKnow
              fact="Lewis Mohr Insurance Agency has been serving Baton Rouge and all of Louisiana since 1968, and has been awarded the BBB Torch for Ethics."
              source=""
            />
            <Service
              serviceName="ATV Insurance"
              serviceURL={"/atv-insurance/"}
              serviceDescription="Protects your ATV and those who ride it. In some areas, basic ATV insurance is required by law."
            />

            <Service
              serviceName="Boat & Watercraft Insurance"
              serviceURL={"/boat-watercraft-insurance/"}
              serviceDescription="Covers your boats and other watercraft, covering damage to the hull, bodily injury, and more."
            />
            <Service
              serviceName="Motorcyle Insurance"
              serviceURL={"/motorcycle-insurance/"}
              serviceDescription="Made specific for motorcycles, it covers property damage, bodily injury, uninsured motorists, and more."
            />
            <Service
              serviceName="RV Insurance"
              serviceURL={"/rv-insurance/"}
              serviceDescription="Made specific for RVs, it covers property damage, bodily injury, uninsured motorists, and more."
            />
          </ServiceList>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="an insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default PersonalInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(
      relativePath: { eq: "baton-rouge-personal-insurance-bg.jpg" }
    ) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-25.jpg" }) {
      ...mainBg
    }
  }
`;
